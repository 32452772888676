import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import StatusMessage from "../status-message";

import styles from "./video-player.module.scss";

export const VideoPlayer = ({ url, isSelected, isRadio }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  if (error) {
    return (
      <div className={styles.root}>
        <StatusMessage
          show
          status="error"
          message="Cannot play video content"
        />
      </div>
    );
  }

  return (
    <div className={`${styles.root} ${isRadio ? styles.isRadio : ""}`}>
      {isSelected && (
        <ReactPlayer
          url={url}
          controls
          playing
          onError={() => setError(true)}
          onReady={() => setLoaded(true)}
        />
      )}
      {!loaded && <div className="overlay" />}
    </div>
  );
};

VideoPlayer.defaultProps = {
  isSelected: false,
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
};
